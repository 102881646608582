<template>
	<label :for="nameDF" class="cursor-pointer mb-0">
		<slot></slot>
		<input type="file" :id="nameDF" :name="nameDF" :accept="acceptedDF" v-on:change="onFileChanged" v-if="!disabledDF" class="d-none" :multiple="maxFilesDF>1">
	</label>
</template>
<script type="text/javascript">
	export default {
		props: {
			name: String,
			disabled: Boolean,
			accepted: String,
			maxFiles: Number
		},
		methods: {
			onFileChanged(event){
				if (this.disabledDF) {return}
				var files = event.target.files || event.dataTransfer.files
				if (files && files.length > 0) {
					if (this.maxFilesDF>1) {
						this.$emit('update:modelValue', files)
						this.$emit('fileChange', files)
					}else{
						this.$emit('update:modelValue', files[0])
						this.$emit('fileChange', files[0])
					}
				}
				$(this.$el).find('input').value=''
			}
		},
		computed: {
			nameDF(){
				return this.name || '1'
			},
			disabledDF(){
				return this.disabled || false
			},
			acceptedDF(){
				return this.accepted || ''
			},
			maxFilesDF(){
				return this.maxFiles || 1
			}
		}
	}
</script>